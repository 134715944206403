import { createContext, useState, useEffect } from 'react';
import { hasStorageAvailable } from '@klv/shared/utils/storage';

import type { ReactNode } from 'react';
import type { ISavedJobsContextType } from './types';


const initialState = hasStorageAvailable() ? JSON.parse(localStorage.getItem('savedJobs') || '[]') : [];

export const SavedJobsContext = createContext<ISavedJobsContextType | null>(null);

const SavedJobsProvider = ({ children }: { children: ReactNode; }) => {
  const [state, setState] = useState(initialState);

  // Save to local storage when state changes
  useEffect(() => {
    localStorage.setItem('savedJobs', JSON.stringify(state));
  }, [state]);

  const toggleSavedJob = (jobId: number) => {
    setState((prevState: number[]) => {
      const isJobSaved = prevState.includes(jobId);
      if (isJobSaved) {
        return prevState.filter((id: number) => id !== jobId);
      } else {
        return [...prevState, jobId];
      }
    });
  };

  return (
    <SavedJobsContext.Provider value={{ savedJobs: state, toggleSavedJob }}>
      {children}
    </SavedJobsContext.Provider>
  );
};

export default SavedJobsProvider;
