import { createInstance } from 'i18next';

import enGB from '@klaviyo-mkt/translations/en-GB.json';
import enUS from '@klaviyo-mkt/translations/en-US.json';
import frFR from '@klaviyo-mkt/translations/fr-FR.json';
import deDE from '@klaviyo-mkt/translations/de-DE.json';
import esES from '@klaviyo-mkt/translations/es-ES.json';
import itIT from '@klaviyo-mkt/translations/it-IT.json';
// TRANSLATION_IMPORTS

const initI18n = (langCode: string) => {
  const i18n = createInstance();

  i18n.init({
    resources: {
      'en-US': {
        translation: enUS,
      },
      'en-GB': {
        translation: enGB,
      },
      'fr-FR': {
        translation: frFR,
      },
      'de-DE': {
        translation: deDE,
      },
      'es-ES': {
        translation: esES,
      },
      'it-IT': {
        translation: itIT,
      },
      // TRANSLATION_RESOURCES
    },
    debug: process.env.NODE_ENV === 'development',
    lng: langCode,
    fallbackLng: 'en-US',
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

  return i18n;

};

export default initI18n;
