import * as Sentry from '@sentry/gatsby';

import { storeUrlTrackingParams } from '@klaviyo-mkt/gatsby-components/shared/utils/attribution';
/* @ts-ignore: vanilla javascript file. */
import { handleGclid } from '@klaviyo-mkt/gatsby-components/shared/utils/gclid';
import { handleLeadSource } from '@klaviyo-mkt/gatsby-components/shared/utils/leadSource';
import { gtmPageView } from '@klaviyo-mkt/gatsby-components/shared/utils/google-tag-manager';

import type { IThemeOptions } from '@klaviyo-mkt/gatsby-theme-core/src/types';
import type { GatsbyBrowser, RouteUpdateArgs } from 'gatsby';

export interface IGatsbyBrowser extends GatsbyBrowser {
  onRouteUpdate?(args: RouteUpdateArgs & {
    location: RouteUpdateArgs['location'] & {
      state: Record<string, any>;
    };
  }, options: IThemeOptions): void;
}

export const onRouteUpdate: IGatsbyBrowser['onRouteUpdate'] = ({ location, prevLocation }, pluginOptions) => {
  const { pathname, search, state } = location;

  if (
    pluginOptions?.siteUrl?.startsWith('https://www.klaviyo.com')
    && pluginOptions?.pathPrefix
    && process.env.GATSBY_HOST === 'netlify_create'
  ) {
    if (pathname.startsWith(pluginOptions.pathPrefix)) {
      window.location.replace(location.href.replace(pluginOptions.pathPrefix, ''));
    }
  }

  // Client side redirect for legacy modal links
  if (
    pluginOptions?.siteUrl?.startsWith('https://www.klaviyo.com')
    && search
  ) {
    const pathPrefix = pluginOptions?.pathPrefix || '';
    const searchParams = new URLSearchParams(search);
    const modal = searchParams.get('modal');
    const DEMO = '/demo-request';
    const SIGNUP = '/sign-up';

    if (modal === 'demo') {
      window.location.replace(`${pathPrefix}${DEMO}`);
    }

    if (modal === 'signup') {
      window.location.replace(`${pathPrefix}${SIGNUP}`);
    }
  }

  if (pathname !== '/klaviyo-integration') {
    setTimeout(() => {
      if (!(state?.gtmPageView === false)) {
        if ((location.hash && (prevLocation?.pathname === location?.pathname))) {
          // they clicked an anchor link
        } else {
          gtmPageView();
        }
      }
      handleGclid();
      handleLeadSource();
      storeUrlTrackingParams();
    }, 50);
  }

  if (process.env.GATSBY_HOST) {
    Sentry.setTag('host', process.env.GATSBY_HOST);
  }
};
